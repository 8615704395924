<template>
  <action-history :id="id" v-if="kid" />
  <div v-else />
</template>

<script>
import ActionHistory from "./ActionHistory.vue";
export default {
  components: { ActionHistory },
  name: "KidLink",
  props: ["id"],

  computed: {
    kid() {
      return this.$store.getters.kid;
    },
  },
};
</script>
